import {
  BoxProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { invitation } from "../../types/invitation";
import { RelationshipInvitationItem } from "./RelationshipsInvitationItem";

interface RelationshipsInvitationProps extends BoxProps {
  invitations: invitation[];
}

const RelationshipsInvitation: React.FC<RelationshipsInvitationProps> = ({
  invitations,
}) => {
  return (
    <TableContainer mb={10} w={"full"}>
      <Table size="sm" w={"full"}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th></Th>
            <Th>Routine</Th>
            <Th>Understanding</Th>
            <Th>Mailing</Th>
            <Th>Expires</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invitations.length < 1 && (
            <Tr>
              <Td colSpan={8} textAlign={"center"} fontWeight={"bold"} py={10}>
                No Invitations Found
              </Td>
            </Tr>
          )}
          {invitations.map((r: invitation) => {
            return <RelationshipInvitationItem key={r.id} invitation={r} />;
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th></Th>
            <Th>Routine</Th>
            <Th>Understanding</Th>
            <Th>Mailing</Th>
            <Th>Expires</Th>
            <Th isNumeric>Actions</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export { RelationshipsInvitation };
