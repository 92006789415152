import axios from "axios";
import { processSwimSmoothApiError } from "../components/generic/ErrorProcessing";

const getForgottenPassword = async (
  email: string,
  hToken: string
): Promise<void> => {
  try {
    await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/get",
      {
        email: email,
        hToken: hToken,
        captchaSource: "H",
      }
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const verifyForgottenPassword = async (otc: string): Promise<any> => {
  try {
    const verification = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/verify",
      {
        otc: otc,
      }
    );
    return verification.data.payload;
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const changeForgottenPassword = async (
  otc: string,
  password: string
): Promise<void> => {
  try {
    await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/forgot/change",
      {
        otc: otc,
        password: password,
      }
    );
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

const changePassword = async (data: any): Promise<any> => {
  try {
    const result = await axios.post(
      process.env.REACT_APP_API_ROOT_URL + "password/change",
      {
        currentpassword: data.currentPassword,
        password: data.password,
      }
    );
    if (result.data.success) {
      return result.data.payload;
    }
  } catch (error: any) {
    if (error.response) {
      throw new Error(processSwimSmoothApiError(error.response.data));
    } else {
      throw error;
    }
  }
};

export {
  changeForgottenPassword,
  changePassword,
  getForgottenPassword,
  verifyForgottenPassword,
};
