import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  GridItem,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getTraining } from "../../DataAccess/training";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { training } from "../../types/training";
import { checkRole } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BetaBadge } from "../ui/BetaBadge";
import { BoxWrapper } from "../ui/BoxWrapper";
import Loading from "../ui/Loading";
import { TrainingBalanceGraph } from "./TrainingBalanceGraph";

interface TrainingBalanceProps extends BoxProps {}

const TrainingBalance: React.FC<TrainingBalanceProps> = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [loadingTraining, setLoadingTraining] = useState<boolean>(true);
  const [generatingTraining, setGeneratingTraining] = useState<boolean>(false);
  const subscriber = checkRole(accessToken, "read", "trainingBalance");
  const [training, setTraining] = useState<training | null>();

  const getTrainingLocal = async () => {
    const training = await getTraining();
    if (training.generating) {
      setGeneratingTraining(true);
      setLoadingTraining(false);
    } else if (training.notModified) {
      setGeneratingTraining(false);
      setLoadingTraining(false);
    } else {
      setTraining(training.data);
      setGeneratingTraining(false);
      setLoadingTraining(false);
    }
  };

  useEffect(() => {
    getTrainingLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!subscriber) {
    return (
      <TBWrapper>
        <VStack>
          <TrainingBalanceGraph
            subscriber={false}
            trainingBalance={training?.balance}
          />
          <NotAuthorised
            functionText={"Training Balance Tracking"}
            size={"small"}
          />
        </VStack>
      </TBWrapper>
    );
  }

  if (loadingTraining) {
    return (
      <TBWrapper>
        <Loading message="Loading Training Balance" />
      </TBWrapper>
    );
  }

  if (generatingTraining) {
    return (
      <BoxWrapper>
        <Loading message="Generating training summary, this can take up to a minute" />
      </BoxWrapper>
    );
  }

  if (!training) {
    return (
      <TBWrapper>
        <Loading message="Error loading training" />
      </TBWrapper>
    );
  }

  return (
    <TBWrapper>
      <VStack>
        <TrainingBalanceGraph
          subscriber={subscriber}
          trainingBalance={training.balance}
        />
        <Text color={training.balance.colour}>{training.balance.title}</Text>
        <Text>{training.balance.text}</Text>
      </VStack>
    </TBWrapper>
  );
};

const TBWrapper: React.FC<BoxProps> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <BoxWrapper>
      <Heading mb={5} size="md">
        Training Balance
        <>
          <Icon
            cursor="pointer"
            ml={2}
            mt={-4}
            as={InfoIcon}
            onClick={onOpen}
          />
          <Drawer isOpen={isOpen} placement="left" size="md" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>About Training Balance</DrawerHeader>
              <DrawerBody>
                <TBExplainer />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
        <BetaBadge />
      </Heading>
      {children}
    </BoxWrapper>
  );
};

const TBExplainer = () => {
  const titles = [
    "Losing Fitness",
    "Performance-Ready",
    "In Balance",
    "Building Fitness",
    "Cautionary",
    "Over-Training",
  ];
  const descriptions = [
    "Your fitness is fading. Time to get to the pool and follow that black line!",
    "You’re rested and primed to perform well. Good luck if you have a race coming up!",
    "You’re maintaining your fitness. Training stress and recovery are in balance.",
    "You’re in the sweet spot. Your training is contributing to your fitness. Well done!",
    "Go steady! You’re in heavy training right now. Be careful not to overdo it.",
    "Red flag! You’re training very hard. Be wary of injury and take time to recover.",
  ];
  const scoring = [
    "-45 to -25",
    "-25 to -5",
    "-5 to 10",
    "10 to 25",
    "25 to 40",
    "40 to 45",
  ];
  const colours = [
    "#8D9FB4",
    "#35B0F4",
    "#6FCEEC",
    "#38A169",
    "#FF9B63",
    "#E45225",
  ];
  return (
    <>
      <Heading mb={2}>Training Balance</Heading>
      <Text mb={2}>
        With too much training in a short space of time, you risk burnout,
        injury and even illness. Too little and you won't get the progressive
        load required to build more fitness.
      </Text>
      <Text mb={2}>
        Your Swim Training Balance (STB) compares your short term training
        stress (past 7 days) with your long term training stress (weighted
        average of past 6 weeks).
      </Text>
      <Text mb={2}>
        The STB wheel is your easy, once-a-day glance at how your training is
        progressing. Whether that be for a goal, event, or simply to get fitter.
      </Text>
      {titles.map((t: string, index: number) => {
        return (
          <TBexplainerLevel
            title={t}
            description={descriptions[index]}
            colour={colours[index]}
            scoring={scoring[index]}
          />
        );
      })}
    </>
  );
};

interface TBExplainerProps {
  title: string;
  description: string;
  scoring: string;
  colour: string;
}

const TBexplainerLevel: React.FC<TBExplainerProps> = ({
  title,
  description,
  scoring,
  colour,
}) => {
  return (
    <SimpleGrid columns={2} mb={5}>
      <GridItem>
        <HStack>
          <Box bg={colour} w={5} h={5} borderRadius={3}></Box>
          <Text fontWeight={"bold"} color={colour}>
            {title.toUpperCase()}
          </Text>
        </HStack>
      </GridItem>
      <GridItem textAlign={"right"}>
        <Text color={"grey"}>{scoring}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Text>{description}</Text>
      </GridItem>
    </SimpleGrid>
  );
};

export { TrainingBalance };
