import { Button, Text, useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  changePremiumMailingList,
  getRelationshipsForUser,
} from "../../DataAccess/relationships";
import { useAppSelector } from "../../app/hooks";
import { updateRelationshipsState } from "../../features/relationships/relationshipsSlice";
import { selectUserState } from "../../features/user/userSlice";
import { relationship } from "../../types/relationship";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

interface AddMailingMenuProps {
  relationship: relationship;
}

const AddMailingMenu: React.FC<AddMailingMenuProps> = ({ relationship }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { user } = useAppSelector(selectUserState);

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  const changeHandler = async (add: boolean) => {
    toast.closeAll();
    try {
      // Do some stuff
      await changePremiumMailingList(relationship._id, add);
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Change Premium Mailing List"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              {relationship.data.child.emailAddress}{" "}
              {add ? "added to" : "removed from"} premium mailing list.
            </Text>
          </CustomToast>
        );
      });
      // Update the list
      const updatedResponse = await getRelationshipsForUser(user._id);
      dispatch(updateRelationshipsState(updatedResponse));
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              Unable to add / extend relationship, please contact support.
            </Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  if (relationship.data.upgradeToPremiumMailingList) {
    return (
      <Button
        size={"xs"}
        mr={2}
        variant={"warning"}
        onClick={() => {
          changeHandler(false);
        }}
      >
        -
      </Button>
    );
  }

  return (
    <Button
      size={"xs"}
      mr={2}
      variant={"success"}
      onClick={() => {
        changeHandler(true);
      }}
    >
      +
    </Button>
  );
};

export { AddMailingMenu };
