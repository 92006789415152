import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import Pricing from "../../components/pricing/Pricing";
import { selectUserState } from "../../features/user/userSlice";
import { breadcrumbLink } from "../../types/breadcrumb";

const PricingPage = () => {
  const { user } = useAppSelector(selectUserState);
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/pricing", title: "Pricing" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        Pricing
      </Heading>
      {!user && (
        <Text>
          All new customers can{" "}
          <Button
            variant="link"
            onClick={() => navigate("/account/create", { replace: false })}
          >
            create an account
          </Button>{" "}
          with a free seven day 'Routine' trial, no card required, which will
          revert to a free forever 'Guidance' account.
        </Text>
      )}
      <Pricing />
      {!user && (
        <Text>
          Already have an account{" "}
          <Button
            variant="link"
            onClick={() => navigate("/account/create", { replace: false })}
          >
            login here
          </Button>
        </Text>
      )}
    </VStack>
  );
};

export { PricingPage };
