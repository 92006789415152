import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaSwimmer } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import { GoGraph } from "react-icons/go";
import { ImStopwatch } from "react-icons/im";
import { IoIosFlame } from "react-icons/io";
import { MdFace, MdLogin, MdOutlineLocalLibrary } from "react-icons/md";
import { TbPigMoney } from "react-icons/tb";
import { VscTools } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import {
  selectCountryState,
  updateCountryState,
} from "../../features/location/countrySlice";
import {
  selectLocationState,
  updateLocationState,
} from "../../features/location/locationSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkToken } from "../../utils/authHelper";
import { countryToCurrency, reverseGeocode } from "../../utils/locationHelper";
import Loading from "../ui/Loading";
import { SSMenuOption } from "./SSMenuOption";
import { UserMenu } from "./UserMenu";

const HeaderMain: React.FC = () => {
  const { access: accessToken, refresh: refreshToken } =
    useAppSelector(selectAuthState);
  const { user } = useAppSelector(selectUserState);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const {
    latitude,
    longitude,
    lastUpdated: lastUpdatedLocation,
  } = useAppSelector(selectLocationState);
  const {
    country,
    currency,
    lastUpdated: lastUpdatedCountry,
  } = useAppSelector(selectCountryState);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLocation, setLoadingLocation] = useState<boolean>(true);
  const [loadingCountry, setLoadingCountry] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Get the users location
    const getLocationLocal = async () => {
      if ("geolocation" in navigator) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              if (position && position.coords) {
                dispatch(
                  updateLocationState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  })
                );
              } else {
                dispatch(updateLocationState(null));
              }
            },
            (error: any) => {
              dispatch(updateLocationState(null));
            }
          );
        } catch (error: any) {
          dispatch(updateLocationState(null));
        }
      } else {
        dispatch(updateLocationState(null));
      }
      setLoadingLocation(false);
    };
    if (lastUpdatedLocation) {
      const test = new Date(lastUpdatedLocation).getTime();
      const aDayAgo = new Date().getTime() - 24 * 1000 * 60 * 60;
      if (!latitude || !longitude || aDayAgo > test) {
        getLocationLocal();
      } else {
        setLoadingLocation(false);
      }
    } else {
      getLocationLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get the users location
    const getCountryLocal = async () => {
      if (user && latitude && longitude) {
        try {
          const country = await reverseGeocode(latitude, longitude);
          const currency = countryToCurrency(country);
          dispatch(
            updateCountryState({
              country: country,
              currency: currency,
            })
          );
        } catch (error: any) {
          setLoadingCountry(false);
        }
      } else {
        dispatch(updateCountryState(null));
      }
      setLoadingCountry(false);
    };
    if (lastUpdatedCountry) {
      const test = new Date(lastUpdatedCountry).getTime();
      const aDayAgo = new Date().getTime() - 24 * 1000 * 60 * 60;
      if (!country || !currency || aDayAgo > test) {
        getCountryLocal();
      } else {
        setLoadingCountry(false);
      }
    } else {
      getCountryLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, latitude, longitude]);

  useEffect(() => {
    const checkTokenLocal = async () => {
      const authCheckResponse = await checkToken(accessToken, refreshToken);
      setAuthenticated(authCheckResponse.authenticated);
      setLoading(false);
    };
    checkTokenLocal();
  }, [accessToken, refreshToken, user]);

  if (loading) {
    return <Loading message="Loading User" />;
  }

  if (loadingLocation) {
    return <Loading message="Loading Location" />;
  }

  if (loadingCountry) {
    return <Loading message="Loading Country & Currency" />;
  }

  if (!authenticated) {
    return (
      <VStack>
        <Flex
          w="full"
          maxW={[
            "container.sm",
            "container.md",
            "container.lg",
            "container.xl",
          ]}
          p="0"
        >
          <Box
            cursor={"pointer"}
            onClick={() => {
              navigate("/", { replace: false });
            }}
          >
            <Image
              src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
              height={126}
              width={179}
              py={2}
              px={0}
            />
          </Box>
          <Box
            w="full"
            display="flex"
            alignItems="flex-center"
            justifyContent="center"
          >
            <SSMenuOption
              icon={ImStopwatch}
              title="Coaches"
              target="/coaches"
            />
            <SSMenuOption icon={VscTools} title="Tools" target="/tools" />
            <SSMenuOption icon={TbPigMoney} title="Pricing" target="/pricing" />
            <SSMenuOption
              icon={MdFace}
              title="Create Account"
              target="/account/create"
            />
            <SSMenuOption icon={MdLogin} title="Login" target="/login" />
          </Box>
          <Box display="flex">
            <UserMenu />
          </Box>
        </Flex>
      </VStack>
    );
  } else {
    return (
      <VStack>
        <Flex
          w="full"
          maxW={[
            "container.sm",
            "container.md",
            "container.lg",
            "container.xl",
          ]}
          p="0"
        >
          <Box
            cursor={"pointer"}
            onClick={() => {
              navigate("/", { replace: false });
            }}
          >
            <Image
              src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png"
              height={126}
              width={179}
              py={2}
              px={0}
            />
          </Box>
          <Box
            w="full"
            display="flex"
            alignItems="flex-center"
            justifyContent="center"
          >
            <SSMenuOption icon={GoGraph} title="Progress" target="/progress" />
            <SSMenuOption icon={FiTarget} title="Training" target="/training" />
            <SSMenuOption
              icon={FaSwimmer}
              title="Activities"
              target="/activities"
            />
            <SSMenuOption
              icon={IoIosFlame}
              title="Sessions"
              target="/sessions"
            />
            <SSMenuOption
              icon={MdOutlineLocalLibrary}
              title="Library"
              target="/library"
            />
            <SSMenuOption
              icon={ImStopwatch}
              title="Coaches"
              target="/coaches"
            />
            <SSMenuOption icon={VscTools} title="Tools" target="/tools" />
            {user?.data.billing.subscriptionStatus === "None" && (
              <SSMenuOption
                icon={TbPigMoney}
                title="Pricing"
                target="/pricing"
              />
            )}
          </Box>
          <Box display="flex">
            <UserMenu />
          </Box>
        </Flex>
      </VStack>
    );
  }
};

export { HeaderMain };
