import { useContext, useEffect } from "react";
import { ForgottenPassword } from "../../components/auth/ForgottenPassword";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";

const ForgottenPasswordPage = () => {
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumbLinks(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ForgottenPassword />;
};

export { ForgottenPasswordPage };
