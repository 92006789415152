import { decodeJwt } from "jose";
import { Dispatch } from "react";
import { refresh } from "../DataAccess/oauth";
import { getUserById } from "../DataAccess/users";
import { store } from "../app/store";
import { updateActivitiesForLinkingState } from "../features/activity/activitiesForLinkingSlice";
import { updateActivitiesState } from "../features/activity/activitiesSlice";
import { updateActivityState } from "../features/activity/activitySlice";
import { updateAffiliateState } from "../features/affiliate/affiliateSlice";
import { updateAccessToken, updateAuthState } from "../features/auth/authSlice";
import { updateCoachesState } from "../features/coach/coachesSlice";
import { updateInvitationsState } from "../features/invitations/invitationsSlice";
import { updateAreasState } from "../features/library/areasSlice";
import { updateCountryState } from "../features/location/countrySlice";
import { updateLocationState } from "../features/location/locationSlice";
import { updateRelationshipsState } from "../features/relationships/relationshipsSlice";
import { updateSessionRouletteState } from "../features/session/sessionRouletteSlice";
import { updateSessionState } from "../features/session/sessionSlice";
import { updateSessionTypesState } from "../features/session/sessionTypesSlice";
import { updateSessionsState } from "../features/session/sessionsSlice";
import { updateVirtualSquadState } from "../features/session/virtualSquadSlice";
import { updateWbssWeeksState } from "../features/session/wbssWeeksSlice";
import { updateFitnessState } from "../features/training/fitnessSlice";
import { updateTrainingTimelineState } from "../features/trainingTimeline/trainingTimelineSlice";
import { updateUserState } from "../features/user/userSlice";
import {
  SSAccessJWTPayloadRaw,
  authorisationCheckResponse,
  authority,
  role,
} from "../types/auth";

const checkToken = async (
  accessToken: string | null,
  refreshToken: string | null
): Promise<authorisationCheckResponse> => {
  let authenticated = false;
  let updated = false;
  let userId;
  try {
    // Get the access token, if there is not access token we need to login
    if (accessToken) {
      // Decode the access token, if we have no payload or expiry then we need to log in
      const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
        accessToken
      ) as unknown as SSAccessJWTPayloadRaw;
      if (accessTokenPayload && accessTokenPayload.exp) {
        // Check the expiry, if the token has expired we will try the refresh token
        const accessTokenExpiry = accessTokenPayload.exp;
        const now = new Date().getTime() / 1000;
        const isAuthenticated = accessTokenExpiry > now;
        if (isAuthenticated) {
          authenticated = true;
          userId = accessTokenPayload.user;
        } else {
          if (refreshToken) {
            const refreshResult = await refresh(refreshToken);
            accessToken = refreshResult.data.access_token;
            // Success - continue
            authenticated = true;
            userId = refreshResult.data.userId;
            updated = true;
          }
        }
      }
    }
  } catch (error) {
    // Go to the login page
  }
  return {
    updated,
    authenticated,
    accessToken,
    refreshToken,
    userId,
  };
};

const checkRole = (
  accessToken: string | null,
  action: string,
  role: string
): boolean => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;

    const processedRoles = accessTokenPayload.roles.map((role: string) => {
      return JSON.parse(role);
    });

    const requestedRole = processedRoles.filter((r: role) => r.id === role);
    const adminRole = processedRoles.filter((r: role) => r.id === "admin");
    const superAdminRole = processedRoles.filter(
      (r: role) => r.id === "superadmin"
    );

    try {
      if (requestedRole.length >= 1) {
        // Requested role must contain requested action which must be granted
        const authorityAll = requestedRole[0].authorities.filter(
          (a: authority) => a.action === "*ALL"
        );
        if (authorityAll[0] && authorityAll[0].granted) {
          return true;
        }
        const authority = requestedRole[0].authorities.filter(
          (a: authority) => a.action === action
        );
        if (authority[0] && authority[0].granted) {
          return true;
        }
      }

      if (adminRole.length >= 1) {
        // Admin role action must still be granted but will be *ALL!
        const authorityAll = adminRole[0].authorities.filter(
          (a: authority) => a.action === "*ALL"
        );
        if (authorityAll[0] && authorityAll[0].granted) {
          return true;
        }
        const authority = adminRole[0].authorities.filter(
          (a: authority) => a.action === action
        );
        if (authority[0] && authority[0].granted) {
          return true;
        }
      }

      if (superAdminRole.length >= 1) {
        // Super Admin role action must still be granted but may be *ALL!
        const authorityAll = superAdminRole[0].authorities.filter(
          (a: authority) => a.action === "*ALL"
        );
        if (authorityAll[0] && authorityAll[0].granted) {
          return true;
        }
        const authority = superAdminRole[0].authorities.filter(
          (a: authority) => a.action === action
        );
        if (authority[0] && authority[0].granted) {
          return true;
        }
      }

      // If none of the above then Foxtrot Romeo Oscar
      return false;
    } catch (error: any) {
      //  Anything goes wrong default to Foxtrot Romea Oscar;
      return false;
    }
  }
  return false;
};

const getLevel = (accessToken: string | null): string => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;

    return accessTokenPayload.level;
  }
  return "Guidance";
};

const getLevelInSentence = (accessToken: string | null): string => {
  if (accessToken) {
    let prefix = "a";
    const level = getLevel(accessToken);
    if (level === "Understanding" || level === "Ultimate") {
      prefix = "an";
    }
    return prefix + " '" + level + "' subscription";
  }
  return "";
};

const checkLevel = (accessToken: string | null, level: string): boolean => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;

    if (accessTokenPayload.level === level) {
      return true;
    }
  }
  return false;
};

const checkLevelInArray = (
  accessToken: string | null,
  levels: string[]
): boolean => {
  if (accessToken) {
    const accessTokenPayload: SSAccessJWTPayloadRaw = decodeJwt(
      accessToken
    ) as unknown as SSAccessJWTPayloadRaw;
    if (levels && levels.includes(accessTokenPayload.level)) {
      return true;
    }
  }
  return false;
};

const forceRefreshToken = async (dispatch: any): Promise<void> => {
  const { authState } = store.getState();
  let refreshToken = authState.refresh;
  if (refreshToken) {
    console.log("AuthState:", authState);
    const refreshResult = await refresh(refreshToken);
    // Get the user object as it is likely changed too.
    console.log("AuthState User ID", authState.userId);
    if (authState.userId) {
      const userResponse = await getUserById(authState.userId);
      console.log(userResponse?._id);
      dispatch(updateUserState(userResponse));
    }
    // Write tokens and user state
    dispatch(updateAccessToken(refreshResult.data.access_token));
  }
};

const clearState = (dispatch: Dispatch<any>, includeAuth: boolean) => {
  if (includeAuth) {
    dispatch(updateAuthState(null));
  }
  dispatch(updateUserState(null));
  dispatch(updateActivitiesState(null));
  dispatch(updateActivitiesForLinkingState(null));
  dispatch(updateActivityState(null));
  dispatch(updateSessionTypesState(null));
  dispatch(updateSessionsState(null));
  dispatch(updateWbssWeeksState(null));
  dispatch(updateSessionState(null));
  dispatch(updateSessionRouletteState(null));
  dispatch(updateAreasState(null));
  dispatch(updateTrainingTimelineState(null));
  dispatch(updateLocationState(null));
  dispatch(updateCountryState(null));
  dispatch(updateCoachesState(null));
  dispatch(updateVirtualSquadState(null));
  dispatch(updateFitnessState(null));
  dispatch(updateRelationshipsState(null));
  dispatch(updateInvitationsState(null));
  dispatch(updateAffiliateState(null));
};

export {
  checkLevel,
  checkLevelInArray,
  checkRole,
  checkToken,
  clearState,
  forceRefreshToken,
  getLevel,
  getLevelInSentence,
};
