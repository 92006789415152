import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const CoachBox: ComponentStyleConfig = {
  parts: ["main"],
  baseStyle: (props: any) => ({
    main: {
      backgroundColor: mode("ssBoxBackgroundLight", "ssBoxBackgroundDark")(props),
      borderWidth: "1px",
      borderColor: mode("ssBoxBackgroundLight", "ssBoxBackgroundDark")(props),
      borderRadius: "lg",
      cursor: "pointer",
      overflow: "hidden",
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};
