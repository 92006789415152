import { Box, Button, Heading, HStack, Icon, ListItem, Text, UnorderedList, useColorModeValue, useMediaQuery, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { LastWeek } from "../../components/trainingTimeline/LastWeek";
import { NextWeek } from "../../components/trainingTimeline/NextWeek";
import { ThisWeek } from "../../components/trainingTimeline/ThisWeek";
import FilledBar from "../../components/ui/FilledBar";
import Loading from "../../components/ui/Loading";
import { getTrainingTimelineCurrent, progressTrainingTimelineById, slideTrainingTimelineById } from "../../DataAccess/trainingTimelines";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectTrainingTimelineState, updateTrainingTimelineState } from "../../features/trainingTimeline/trainingTimelineSlice";
import { selectUserState } from "../../features/user/userSlice";
import { checkRole, getLevel } from "../../utils/authHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { CreateTrainingTimeline } from "./CreateTrainingTimeline";

const ViewTrainingTimeline = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const boxColorMode = useColorModeValue("ssBoxBackgroundLight", "ssBoxBackgroundDark");
  const [loadingTrainingTimeline, setLoadingTrainingTimeline] = useState<boolean>(true);
  const { trainingTimeline, lastUpdated: lastUpdatedTrainingTimeline } = useAppSelector(selectTrainingTimelineState);
  const { user } = useAppSelector(selectUserState);
  const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getTrainingTimelineLocal = async () => {
      try {
        const ttl = await getTrainingTimelineCurrent();
        dispatch(updateTrainingTimelineState(ttl));
        setLoadingTrainingTimeline(false);
      } catch (error) {
        dispatch(updateTrainingTimelineState(null));
        setLoadingTrainingTimeline(false);
      }
    };
    if (lastUpdatedTrainingTimeline) {
      const test = new Date(lastUpdatedTrainingTimeline).getTime();
      const anHourAgo = new Date().getTime() - 1000 * 60 * 60;
      if (!trainingTimeline || anHourAgo > test) {
        getTrainingTimelineLocal();
      } else {
        setLoadingTrainingTimeline(false);
      }
    } else {
      getTrainingTimelineLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const refreshTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    try {
      const ttl = await getTrainingTimelineCurrent();
      dispatch(updateTrainingTimelineState(ttl));
    } catch (error) {
      dispatch(updateTrainingTimelineState(null));
    }
    setLoadingTrainingTimeline(false);
  };

  const progressTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    if (trainingTimeline) {
      if (trainingTimeline.data.status === "Active") {
        const ttl = await progressTrainingTimelineById(trainingTimeline._id);
        dispatch(updateTrainingTimelineState(ttl));
      }
    }
    setLoadingTrainingTimeline(false);
  };

  const slideTrainingTimeline = async () => {
    setLoadingTrainingTimeline(true);
    if (trainingTimeline) {
      if (trainingTimeline.data.status === "Active") {
        const ttl = await slideTrainingTimelineById(trainingTimeline._id);
        dispatch(updateTrainingTimelineState(ttl));
      }
    }
    setLoadingTrainingTimeline(false);
  };

  if (loadingTrainingTimeline) {
    return <Loading message="Loading Training Timeline" />;
  }

  if (!user || !user) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Training Plans
        </Heading>
        <VStack w="full" alignItems="flex-start">
          <Text>
            Well now, this is embarrassing! It shouldn't be possible to get here <b>but</b> here we are. I suppose someone went to the trouble if writing this
            so it's obviously something that someone thought was possible even if a little inprobable!
          </Text>
        </VStack>
      </VStack>
    );
  }

  if (!checkRole(accessToken, "read", "ttl")) {
    if (trainingTimeline) {
      return (
        <VStack w="full" alignItems={"flex-start"}>
          <Heading as="h2" size="xl">
            Training
          </Heading>
          <Text>
            Your training plan '{trainingTimeline.data.name}' running {trainingTimeline.data.subTitle} is still here but training plan features require a
            'Routine' or higher subscription (you are currently subscribed to '{getLevel(accessToken)}').
          </Text>
          <Text>
            You can check and upgrade your subscription level
            <Button
              variant={"link"}
              onClick={() => {
                navigate("/account/subscription");
              }}
            >
              here
            </Button>
            .
          </Text>
        </VStack>
      );
    }
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          Training Plans
        </Heading>
        <NotAuthorised functionText={"Dynamic Training Plans"} size={"full"} />
      </VStack>
    );
  }

  if (!trainingTimeline) {
    return <CreateTrainingTimeline />;
  }

  if (hamburgerMode) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl">
          {trainingTimeline.data.name}
        </Heading>
        <Text>
          {trainingTimeline.data.subTitle} : {trainingTimeline.data.overview.remainingText}
        </Text>
        <Box mt={5} mb={5}>
          <FilledBar
            width={300}
            score={
              trainingTimeline.data.overview.totalWeeks > 0
                ? (trainingTimeline.data.overview.currentWeek / trainingTimeline.data.overview.totalWeeks) * 100
                : 100
            }
            max={100}
            showScore={false}
          />
        </Box>
        <HStack pt={1}>
          <Button
            w={150}
            onClick={() => {
              navigate("/training/settings", { replace: false });
            }}
          >
            Settings
          </Button>
          <Button variant="success" isLoading={loadingTrainingTimeline} onClick={refreshTrainingTimeline}>
            <Icon as={IoMdRefresh} style={{ fill: "white" }} color="white" />
          </Button>
        </HStack>
        <HStack>
          {checkRole(accessToken, "slide", "ttl") && (
            <Button w={150} variant="pink" isLoading={loadingTrainingTimeline} onClick={slideTrainingTimeline}>
              &lt;&lt; Slide
            </Button>
          )}
          {checkRole(accessToken, "progress", "ttl") && (
            <Button w={150} variant="pink" isLoading={loadingTrainingTimeline} onClick={progressTrainingTimeline}>
              Progress &gt;&gt;
            </Button>
          )}
        </HStack>
        <VStack pt={2} w="full" alignItems="flex-start">
          <LastWeek user={user} trainingTimeline={trainingTimeline} />
          <ThisWeek user={user} trainingTimeline={trainingTimeline} />
          <NextWeek user={user} trainingTimeline={trainingTimeline} setLoadingTrainingTimeline={setLoadingTrainingTimeline} />
        </VStack>
        <VStack w="full" alignItems="flex-start">
          <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
            <VStack alignItems="flex-start" w="full" mb={2}>
              {trainingTimeline.data.overview.currentWeek === 1 && (
                <Heading as="h4" size="md">
                  Plan Overview
                </Heading>
              )}
              <UnorderedList w="full">
                {trainingTimeline.data.overview.breakdown.map((item: any, itemIndex: number) => {
                  if (item.blockType === "audit") {
                    return (
                      <ListItem key={itemIndex} mb={2} ml={10} color="gray">
                        <b>Audit</b>:<br />
                        <i>
                          {item.description.map((line: any, lineIndex: number) => {
                            return line.data;
                          })}
                        </i>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem key={itemIndex} mb={2} ml={10}>
                      <b>{item.title}</b>
                      <br />
                      {item.subTitle}
                      <br />
                      <i>
                        {item.description.map((line: any, lineIndex: number) => {
                          return line.data;
                        })}
                      </i>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </VStack>
          </Box>
          {trainingTimeline.type !== "*SPECIAL" && (
            <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
              <VStack alignItems="flex-start" w="full" mb={2}>
                {trainingTimeline.data.overview.currentWeek === 1 && (
                  <Heading as="h4" size="md">
                    How It Works
                  </Heading>
                )}
                <UnorderedList>
                  <ListItem mb={2} ml={10}>
                    Your plan adapts each week depending on how you’re doing.
                  </ListItem>
                  <ListItem mb={2} ml={10}>
                    Adjust the number of sessions anytime if it’s too much or too little.
                  </ListItem>
                  <ListItem mb={2} ml={10}>
                    Stick to the plan. For best results, stick to the recommended sessions.
                  </ListItem>
                  <ListItem mb={2} ml={10}>
                    Each weekend your sessions for next week will be generated based on your fitness, fatigue, training balance and plan settings. If you've
                    done extra activities these will be taken into account as will doing less training than planned.
                  </ListItem>
                </UnorderedList>
              </VStack>
            </Box>
          )}
        </VStack>
      </VStack>
    );
  }

  return (
    <VStack w="full">
      <Heading as="h2" size="xl">
        {trainingTimeline.data.name}
      </Heading>
      <Text>
        {trainingTimeline.data.subTitle} : {trainingTimeline.data.overview.remainingText}
      </Text>
      <Box mt={5} mb={5}>
        <FilledBar
          width={800}
          score={
            trainingTimeline.data.overview.totalWeeks > 0 ? (trainingTimeline.data.overview.currentWeek / trainingTimeline.data.overview.totalWeeks) * 100 : 100
          }
          max={100}
          showScore={false}
        />
      </Box>
      <HStack pt={1}>
        {checkRole(accessToken, "slide", "ttl") && (
          <Button w={150} variant="pink" isLoading={loadingTrainingTimeline} onClick={slideTrainingTimeline}>
            &lt;&lt; Slide
          </Button>
        )}
        <Button
          w={150}
          onClick={() => {
            navigate("/training/settings", { replace: false });
          }}
        >
          Settings
        </Button>
        <Button variant="success" isLoading={loadingTrainingTimeline} onClick={refreshTrainingTimeline}>
          <Icon as={IoMdRefresh} />
        </Button>
        {checkRole(accessToken, "progress", "ttl") && (
          <Button w={150} variant="pink" isLoading={loadingTrainingTimeline} onClick={progressTrainingTimeline}>
            Progress &gt;&gt;
          </Button>
        )}
      </HStack>
      <HStack pt={2} w="full" alignItems="flex-start">
        <LastWeek user={user} trainingTimeline={trainingTimeline} />
        <ThisWeek user={user} trainingTimeline={trainingTimeline} />
        <NextWeek user={user} trainingTimeline={trainingTimeline} setLoadingTrainingTimeline={setLoadingTrainingTimeline} />
      </HStack>
      <HStack w="full" alignItems="flex-start">
        <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
          <VStack alignItems="flex-start" w="full" mb={2}>
            {trainingTimeline.data.overview.currentWeek === 1 && (
              <Heading as="h4" size="md">
                Plan Overview
              </Heading>
            )}
            <UnorderedList w="full">
              {trainingTimeline.data.overview.breakdown.map((item: any, itemIndex: number) => {
                if (item.blockType === "audit") {
                  return (
                    <ListItem key={itemIndex} mb={2} ml={10} color="gray">
                      <b>Audit</b>:<br />
                      <i>
                        {item.description.map((line: any, lineIndex: number) => {
                          return line.data;
                        })}
                      </i>
                    </ListItem>
                  );
                }
                return (
                  <ListItem key={itemIndex} mb={2} ml={10}>
                    <b>{item.title}</b>
                    <br />
                    {item.subTitle}
                    <br />
                    <i>
                      {item.description.map((line: any, lineIndex: number) => {
                        return line.data;
                      })}
                    </i>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </VStack>
        </Box>
        {trainingTimeline.type !== "*SPECIAL" && (
          <Box bg={boxColorMode} p={4} borderRadius={10} w="full" h="full">
            <VStack alignItems="flex-start" w="full" mb={2}>
              {trainingTimeline.data.overview.currentWeek === 1 && (
                <Heading as="h4" size="md">
                  How It Works
                </Heading>
              )}
              <UnorderedList>
                <ListItem mb={2} ml={10}>
                  Your plan adapts each week depending on how you’re doing.
                </ListItem>
                <ListItem mb={2} ml={10}>
                  Adjust the number of sessions anytime if it’s too much or too little.
                </ListItem>
                <ListItem mb={2} ml={10}>
                  Stick to the plan. For best results, stick to the recommended sessions.
                </ListItem>
                <ListItem mb={2} ml={10}>
                  Each weekend your sessions for next week will be generated based on your fitness, fatigue, training balance and plan settings. If you've done
                  extra activities these will be taken into account as will doing less training than planned.
                </ListItem>
              </UnorderedList>
            </VStack>
          </Box>
        )}
      </HStack>
    </VStack>
  );
};

export { ViewTrainingTimeline };
