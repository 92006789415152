import {
  BoxProps,
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getTraining } from "../../DataAccess/training";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { training, trainingSummaryPeriod } from "../../types/training";
import { checkRole } from "../../utils/authHelper";
import { dp } from "../../utils/coreHelper";
import { formatDurationLong } from "../../utils/dateHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BoxWrapper } from "../ui/BoxWrapper";
import Loading from "../ui/Loading";
import { SingleValueDisplay } from "../ui/SingleValueDisplay";
import { SingleValueWithUnitDisplay } from "../ui/SingleValueWithUnitDisplay";

interface TrainingSummaryProps extends BoxProps {}

const TrainingSummary: React.FC<TrainingSummaryProps> = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const subscriber = checkRole(accessToken, "read", "trainingSummary");
  const [loadingTraining, setLoadingTraining] = useState<boolean>(true);
  const [generatingTraining, setGeneratingTraining] = useState<boolean>(false);
  const [training, setTraining] = useState<training | null>();

  const getTrainingLocal = async () => {
    const training = await getTraining();
    if (training.generating) {
      setGeneratingTraining(true);
      setLoadingTraining(false);
    } else if (training.notModified) {
      setGeneratingTraining(false);
      setLoadingTraining(false);
    } else {
      setTraining(training.data);
      setGeneratingTraining(false);
      setLoadingTraining(false);
    }
  };

  useEffect(() => {
    getTrainingLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!subscriber) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          Training Summary
        </Heading>
        <VStack>
          <NotAuthorised functionText={"Training Summary"} size={"small"} />
        </VStack>
      </BoxWrapper>
    );
  }

  if (loadingTraining) {
    return (
      <BoxWrapper>
        <Loading message="Loading Training Summary" />
      </BoxWrapper>
    );
  }

  if (generatingTraining) {
    return (
      <BoxWrapper>
        <Loading message="Generating training summary, this can take up to a minute" />
      </BoxWrapper>
    );
  }

  if (!training) {
    return (
      <BoxWrapper>
        <Loading message="Error loading training sumary" />
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper>
      <Heading mb={5} size="md">
        Training Summary
      </Heading>

      <Tabs mt={5} isFitted isLazy size="sm">
        <TabList>
          <Tab>Seven Days</Tab>
          <Tab>Four Weeks</Tab>
          <Tab>Twelve Weeks</Tab>
          <Tab>All Time</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary?.sevenDays}
            />
          </TabPanel>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary?.fourWeeks}
            />
          </TabPanel>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary?.twelveWeeks}
            />
          </TabPanel>
          <TabPanel>
            <TrainingSummaryPeriod
              trainingSummaryPeriod={training.summary?.allTime}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </BoxWrapper>
  );
};

interface TrainingSummaryPeriodProps extends BoxProps {
  trainingSummaryPeriod: trainingSummaryPeriod | null | undefined;
}

const TrainingSummaryPeriod: React.FC<TrainingSummaryPeriodProps> = ({
  trainingSummaryPeriod,
}) => {
  const { user } = useAppSelector(selectUserState);

  if (!user) {
    return null;
  }

  if (!trainingSummaryPeriod) {
    trainingSummaryPeriod = {
      activities: 0,
      distance: {
        m: 0,
        y: 0,
      },
      laps: 0,
      stss: 0,
      swims: 0,
      time: 0,
    };
  }

  let distance =
    user.data.basic.defaultPool.lapUnit === "y"
      ? trainingSummaryPeriod.distance.y
      : trainingSummaryPeriod.distance.m;
  let displayDistance = distance;
  let displayUnit = user.data.basic.defaultPool.lapUnit
    ? user.data.basic.defaultPool.lapUnit
    : "m";
  if (distance > 9999) {
    if (displayUnit === "m") {
      displayDistance = dp(displayDistance / 1000, 1);
      displayUnit = "km";
    } else {
      displayDistance = dp(displayDistance / 1760, 1);
      displayUnit = "miles";
    }
  }
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={2}>
      <GridItem>
        <SingleValueDisplay
          label="No. of Activities"
          value={trainingSummaryPeriod.activities}
        />
      </GridItem>
      <GridItem>
        <SingleValueWithUnitDisplay
          label="Distance"
          value={displayDistance ? displayDistance : 0}
          unit={displayUnit}
        />
      </GridItem>
      <GridItem>
        <SingleValueDisplay
          label="Time"
          value={formatDurationLong(trainingSummaryPeriod.time)}
        />
      </GridItem>
    </Grid>
  );
};

export { TrainingSummary };
