import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Login } from "../../components/auth/Login";
import { BreadcrumbContext, breadcrumbContextType } from "../../components/context/BreadcrumbContext";

interface navigationState {
  requestedLocation?: string;
}

const LoginPage = () => {
  const location = useLocation();
  const { requestedLocation } = location.state ? (location.state as navigationState) : { requestedLocation: "" };
  const { setBreadcrumbLinks } = useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumbLinks(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Login requestedLocation={requestedLocation} />;
};

export { LoginPage };
