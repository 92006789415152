import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getForgottenPassword } from "../../DataAccess/passwords";
import { createToast } from "../../utils/toastHelper";
import { MessageDisplay } from "../generic/MessageDisplay";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";

const ForgottenPassword = (props: any) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const captchaRef = useRef<HCaptcha>(null);
  const { colorMode } = useColorMode();

  const {
    control: controlForgottenPassword,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      hCaptcha: "",
      hToken: "",
    },
    mode: "all",
  });

  const handleForgottenPassword = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      await getForgottenPassword(data.email, data.hToken);
      try {
        captchaRef.current?.resetCaptcha();
      } catch (error) {
        // Nothing doing
      }
      setSuccess(true);
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Forgotten Password"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to reset password, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
    }
  };

  const handleHCaptchaVerificationSuccess = (token: string) => {
    if (token) {
      setValue("hToken", token);
    }
    trigger();
  };

  const handleHCaptchaExpire = () => {
    setValue("hToken", "");
    trigger();
  };

  if (typeof process.env.REACT_APP_HCAPTCHA_SITE_KEY === "undefined") {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Change Password
        </Heading>
        <MessageDisplay status="error" title="Could not get keys!">
          <Text>HCaptcha Keys not configured, please contact support.</Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (success) {
    return (
      <VStack spacing={2} alignItems="flex-start">
        <Heading>Password Reset</Heading>
        <Text>
          If we find your account we'll send you further instructions. Emails
          may take up to 5 minutes to arrive, please try and resist the urge to
          request another reset too quickly! Once you have reset your password
          you can{" "}
          <Button
            variant="link"
            onClick={() => navigate("/login", { replace: false })}
          >
            login
          </Button>
          .
        </Text>
      </VStack>
    );
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(handleForgottenPassword)}
      w="full"
      p={5}
      spacing={3}
      alignItems="flex-start"
    >
      <VStack spacing={2} alignItems="flex-start">
        <Heading>Password Reset</Heading>
        <Text>Forgotten your password? No sweat, it happens 😀.</Text>
        <Text>
          Enter your email address and if we find you account we'll send you
          further instructions. Emails may take up to 5 minutes to arrive,
          please try and resist the urge to request another reset too quickly!
        </Text>
      </VStack>
      <FormControl pb={1}>
        <FormLabel>
          Email
          {errors.email && (
            <span className="formError">{errors.email.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlForgottenPassword}
          rules={{
            required: "Email address is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Email address is invalid",
            },
          }}
          name="email"
          render={({ field: { ref, ...restField } }) => (
            <Input {...restField} placeholder="" />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>Prove you're human!</FormLabel>
        <Controller
          control={controlForgottenPassword}
          name="hCaptcha"
          render={({ field: { ref, ...restField } }) => (
            <HCaptcha
              sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY as string}
              onVerify={(token, ekey) =>
                handleHCaptchaVerificationSuccess(token)
              }
              onExpire={() => handleHCaptchaExpire()}
              onChalExpired={() => handleHCaptchaExpire()}
              onError={(event: string) => {
                console.log("Error", event);
              }}
              ref={captchaRef}
              theme={colorMode}
            />
          )}
        />
        <Controller
          control={controlForgottenPassword}
          rules={{ required: "HCaptcha Token is required" }}
          name="hToken"
          render={({ field: { ref, ...restField } }) => (
            <Input {...restField} type="hidden" />
          )}
        />
      </FormControl>
      <Button isDisabled={!isValid} isLoading={isSubmitting} type="submit">
        Request Password Reset
      </Button>
    </VStack>
  );
};

export { ForgottenPassword };
