import { Box, BoxProps, Heading, Image, SimpleGrid, Text, useColorModeValue, VStack } from "@chakra-ui/react";

interface TypeSelectorProps extends BoxProps {
  typeInput: string;
  subTypeInput: string;
  setTypeInput: any;
  setSubTypeInput: any;
}

const TypeSelector: React.FC<TypeSelectorProps> = ({ typeInput, subTypeInput, setTypeInput, setSubTypeInput }) => {
  const boxColorMode = useColorModeValue("ssBoxBackgroundLight", "ssBoxBackgroundDark");
  const selectedBoxColorMode = useColorModeValue("ssNeonOrangeLight", "ssNeonOrangeDark");
  const backgroundColorMode = useColorModeValue("ssBackgroundLight", "ssBackgroundDark");

  const isSelectedBorder = (type: string, subType: string): number => {
    if (typeInput === type && subTypeInput === subType) {
      return 3;
    }
    return 3;
  };

  const isSelectedBorderRadius = (type: string, subType: string): number => {
    if (typeInput === type && subTypeInput === subType) {
      return 7;
    }
    return 10;
  };

  const isSelected = (type: string, subType: string): string => {
    if (typeInput === type && subTypeInput === subType) {
      return selectedBoxColorMode;
    }
    return backgroundColorMode;
  };

  const types = [
    ["Event", "Triathlon", "Triathlon Plan", "https://d3n8k51hasny1.cloudfront.net/event-tri-large.jpg"],
    ["Event", "Open", "Open Water Event Plan", "https://d3n8k51hasny1.cloudfront.net/event-ow-large.jpg"],
    ["Event", "Pool", "Pool Event Plan", "https://d3n8k51hasny1.cloudfront.net/event-pool-large.jpg"],
    ["Distance", "Open", "Open Water Challenge Plan", "https://d3n8k51hasny1.cloudfront.net/distance-ow-large.jpg"],
    ["Distance", "Pool", "Pool Challenge Plan", "https://d3n8k51hasny1.cloudfront.net/distance-pool-large.jpg"],
  ];
  return (
    <VStack w="full" alignItems="flex-start">
      <Heading as="h3" size="md" pt={5}>
        Event Specific Plans
      </Heading>
      <Text pb={2}>
        Event specific plans work towards a common goal, the event, with everyone getting the same sessions to work through each week. To get the most out of
        these plans make sure you have set your CSS pace and base stroke rate.
      </Text>
      <Heading as="h3" size="md" pt={5}>
        Simply improve your swimming
      </Heading>
      <Text pb={2}>Challenge yourself to swim further, faster and smoother</Text>
      <SimpleGrid columns={[1, 1, 3, 3]} spacing={5} w="full">
        {types
          .filter((type: any) => {
            return type[0] === "Distance";
          })
          .map((type: any) => {
            return (
              <Box
                key={type[0] + type[1]}
                w="full"
                bg={boxColorMode}
                borderWidth={isSelectedBorder(type[0], type[1])}
                borderColor={isSelected(type[0], type[1])}
                borderRadius={10}
                boxSizing="border-box"
                cursor="pointer"
                onClick={() => {
                  setTypeInput(type[0]);
                  setSubTypeInput(type[1]);
                }}
              >
                <Image borderTopRadius={isSelectedBorderRadius(type[0], type[1])} src={type[3]} />
                <Text p={2} px={3}>
                  {type[2]}
                </Text>
              </Box>
            );
          })}
      </SimpleGrid>
      <VStack w="full" alignItems="flex-start" pt={5}>
        <Heading as="h3" size="md">
          Work towards an event
        </Heading>
        <Text pb={2}>Reach your goals faster, with training plans that adapt to you based on your swimming</Text>
        <SimpleGrid columns={[1, 1, 3, 3]} spacing={5} w="full">
          {types
            .filter((type: any) => {
              return type[0] === "Event";
            })
            .map((type: any) => {
              return (
                <Box
                  key={type[0] + type[1]}
                  w="full"
                  bg={boxColorMode}
                  borderWidth={isSelectedBorder(type[0], type[1])}
                  borderColor={isSelected(type[0], type[1])}
                  borderRadius={10}
                  boxSizing="border-box"
                  cursor="pointer"
                  onClick={() => {
                    setTypeInput(type[0]);
                    setSubTypeInput(type[1]);
                  }}
                >
                  <Image borderTopRadius={isSelectedBorderRadius(type[0], type[1])} src={type[3]} />
                  <Text p={2} px={3}>
                    {type[2]}
                  </Text>
                </Box>
              );
            })}
        </SimpleGrid>
      </VStack>
    </VStack>
  );
};

export { TypeSelector };
