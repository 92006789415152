import {
  BoxProps,
  Button,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaDownLong, FaUpLong } from "react-icons/fa6";
import { MdOutlineTrendingFlat } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getBsrCurrent } from "../../DataAccess/bsr";
import { getCssCurrent } from "../../DataAccess/css";
import { getTraining } from "../../DataAccess/training";
import { useAppSelector } from "../../app/hooks";
import { selectAuthState } from "../../features/auth/authSlice";
import { selectUserState } from "../../features/user/userSlice";
import { bsrEntry } from "../../types/bsr";
import { cssEntry } from "../../types/css";
import { training } from "../../types/training";
import { checkRole } from "../../utils/authHelper";
import { formatPace, formatPaceChange } from "../../utils/dateHelper";
import { NotAuthorised } from "../generic/NotAuthorised";
import { BoxWrapper } from "../ui/BoxWrapper";
import Loading from "../ui/Loading";
import {
  SingleValueWithUnitAndIndictorDisplay,
  SingleValueWithUnitDisplay,
} from "../ui/SingleValueWithUnitDisplay";

interface CssBsrSummaryProps extends BoxProps {}

const CssBsrSummary: React.FC<CssBsrSummaryProps> = () => {
  const { access: accessToken } = useAppSelector(selectAuthState);
  const [loadingTraining, setLoadingTraining] = useState<boolean>(true);
  const [generatingTraining, setGeneratingTraining] = useState<boolean>(false);
  const [training, setTraining] = useState<training | null>();

  const getTrainingLocal = async () => {
    const training = await getTraining();
    if (training.generating) {
      setGeneratingTraining(true);
      setLoadingTraining(false);
    } else if (training.notModified) {
      setGeneratingTraining(false);
      setLoadingTraining(false);
    } else {
      setTraining(training.data);
      setGeneratingTraining(false);
      setLoadingTraining(false);
    }
  };

  useEffect(() => {
    getTrainingLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !checkRole(accessToken, "summary", "css") &&
    !checkRole(accessToken, "summary", "bsr")
  ) {
    return (
      <BoxWrapper>
        <Heading mb={5} size="md">
          CSS & BSR Summary
        </Heading>
        <VStack>
          <NotAuthorised
            functionText={"Full CSS Tools & History / Full BSR Tools & History"}
            size={"small"}
          />
        </VStack>
      </BoxWrapper>
    );
  }

  if (loadingTraining) {
    return (
      <BoxWrapper>
        <Loading message="Loading CSS & BSR" />
      </BoxWrapper>
    );
  }

  if (generatingTraining) {
    return (
      <BoxWrapper>
        <Loading message="Generating training summary, this can take up to a minute" />
      </BoxWrapper>
    );
  }

  if (!training) {
    return (
      <BoxWrapper>
        <Loading message="Error loading CSS & BSR" />
      </BoxWrapper>
    );
  }

  return (
    <BoxWrapper>
      <Heading mb={5} size="md">
        CSS & BSR Summary
      </Heading>
      <CurrentCssBsr />
    </BoxWrapper>
  );
};

interface CurrentCssBsrProps {}

const CurrentCssBsr: React.FC<CurrentCssBsrProps> = () => {
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();
  const [cssEntry, setCssEntry] = useState<cssEntry | null>();
  const [bsrEntry, setBsrEntry] = useState<bsrEntry | null>();
  const neutralIndicator = useColorModeValue("ssPrimaryDark", "ssPrimaryLight");
  const greenIndicator = useColorModeValue(
    "ssNeonGreenDark",
    "ssNeonGreenLight"
  );
  const redIndicator = useColorModeValue("red.500", "red.500");
  const orangeIndicator = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );

  useEffect(() => {
    const getCssAndBsrCurrentLocal = async () => {
      const promises = [];
      promises.push(getCssCurrent());
      promises.push(getBsrCurrent());
      const returns = await Promise.all(promises);
      setCssEntry(returns[0] as cssEntry);
      setBsrEntry(returns[1] as bsrEntry);
    };
    getCssAndBsrCurrentLocal();
  }, []);

  if (!user) {
    return null;
  }

  const lane =
    user.data.basic.lane && user.data.basic.lane >= 0
      ? user.data.basic.lane
      : 2;
  const unit = user.data.basic.defaultPool.lapUnit;

  return (
    <VStack w={"full"}>
      <Grid templateColumns="repeat(6, 1fr)" gap={2} m={4} w={"full"}>
        <GridItem colSpan={3}>
          {/* CSS */}
          <SingleValueWithUnitDisplay
            label={
              cssEntry && cssEntry.type === "defaulted"
                ? "Defaulted CSS for lane " + lane
                : "Current CSS"
            }
            unit={"/100" + unit}
            value={
              cssEntry
                ? unit === "y"
                  ? formatPace(cssEntry.y.secondsPer100)
                  : formatPace(cssEntry.m.secondsPer100)
                : "- "
            }
          />
        </GridItem>
        <GridItem colSpan={3}>
          {/* CSS Change */}
          <SingleValueWithUnitAndIndictorDisplay
            label="CSS Improvement (12 Weeks)"
            value={
              cssEntry && cssEntry.change
                ? formatPaceChange(cssEntry.change)
                : " - "
            }
            unit={"/100" + unit}
            indicator={
              cssEntry && cssEntry.change && cssEntry.change > 0
                ? FaUpLong
                : cssEntry && cssEntry.change && cssEntry.change < 0
                ? FaDownLong
                : MdOutlineTrendingFlat
            }
            indicatorColour={
              cssEntry && cssEntry.change && cssEntry.change > 0
                ? redIndicator
                : cssEntry && cssEntry.change && cssEntry.change < 0
                ? greenIndicator
                : orangeIndicator
            }
          />
        </GridItem>
        <GridItem colSpan={3}>
          {/* BSR */}
          <SingleValueWithUnitDisplay
            label="Current BSR"
            value={bsrEntry && bsrEntry.spm ? bsrEntry.spm : "- "}
            unit="spm"
          />
        </GridItem>
        <GridItem colSpan={3}>
          {/* BSR Change */}
          <SingleValueWithUnitAndIndictorDisplay
            label="BSR Improvement (12 Weeks)"
            value={
              bsrEntry && bsrEntry.change && bsrEntry.change > 0
                ? "+" + bsrEntry.change
                : bsrEntry && bsrEntry.change && bsrEntry.change < 0
                ? bsrEntry.change
                : "- "
            }
            unit="spm"
            indicator={
              bsrEntry && bsrEntry.change && bsrEntry.change < 0
                ? FaDownLong
                : bsrEntry && bsrEntry.change && bsrEntry.change > 0
                ? FaUpLong
                : MdOutlineTrendingFlat
            }
            indicatorColour={neutralIndicator}
          />
        </GridItem>
        <GridItem colSpan={6}>
          <SimpleGrid columns={[1, 1, 2, 2]} columnGap={5} rowGap={2} w="full">
            <Button
              onClick={() => {
                navigate("/css/calcsingle", { replace: false });
              }}
            >
              Calculate CSS from Test
            </Button>
            <Button
              onClick={() => {
                navigate("/css/entersingle", { replace: false });
              }}
            >
              Enter Manual CSS
            </Button>
            <Button
              onClick={() => {
                navigate("/bsr/entersingle", { replace: false });
              }}
            >
              Enter BSR
            </Button>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </VStack>
  );
};

export { CssBsrSummary };
