import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

// Define the state of the slice as an object
export interface AuthState {
  access: string | null;
  refresh: string | null;
  userId: string | null;
}

// Define an initial state
const initialState: AuthState = {
  access: null,
  refresh: null,
  userId: null,
};

// Create a slice containing the configuration of the state
// and the reducers functions
const authSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    updateAuthState(state, action: PayloadAction<AuthState | null>) {
      if (action.payload) {
        state.access = action.payload.access;
        state.refresh = action.payload.refresh;
        state.userId = action.payload.userId;
      } else {
        state.access = initialState.access;
        state.refresh = initialState.refresh;
        state.userId = initialState.userId;
      }
    },
    updateAccessToken(state, action: PayloadAction<string>) {
      state.access = action.payload;
    },
    updateRefreshToken(state, action: PayloadAction<string>) {
      state.refresh = action.payload;
    },
  },
});

// Export each reducers function defined in createSlice
export const updateAuthState = authSlice.actions.updateAuthState;
export const updateAccessToken = authSlice.actions.updateAccessToken;
export const updateRefreshToken = authSlice.actions.updateRefreshToken;

export const selectAuthState = (state: RootState): AuthState => {
  return state.authState;
};
export const selectAccessToken = (state: RootState): string | null => {
  return state.authState.access;
};
export const selectRefreshToken = (state: RootState): string | null => {
  return state.authState.refresh;
};
export const selectUser = (state: RootState): string | null => {
  return state.authState.userId;
};

// Export default the slice reducer
export default authSlice.reducer;
