import { BoxProps, Button, Td, Text, Tr, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { MdSupervisorAccount } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  deleteInvitation,
  getInvitationsForUser,
} from "../../DataAccess/invitations";
import { useAppSelector } from "../../app/hooks";
import { updateInvitationsState } from "../../features/invitations/invitationsSlice";
import { selectUserState } from "../../features/user/userSlice";
import { invitation } from "../../types/invitation";
import { formatDateLong } from "../../utils/dateHelper";
import { createToast } from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

interface RelationshipInvitationProps extends BoxProps {
  invitation: invitation;
}

const RelationshipInvitationItem: React.FC<RelationshipInvitationProps> = ({
  invitation,
}) => {
  const { user } = useAppSelector(selectUserState);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const toast = useToast();
  const dispatch = useDispatch();

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  const deleteInvitationHandler = async (
    invitationId: string,
    recipientName: string
  ) => {
    toast.closeAll();
    try {
      setIsDeleting(true);
      // Do some stuff
      await deleteInvitation(invitationId);
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete Relationship"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Invitation deleted for {recipientName}</Text>
          </CustomToast>
        );
      }); // Update the list
      const updatedResponse = await getInvitationsForUser(user._id);
      dispatch(updateInvitationsState(updatedResponse));
      setIsDeleting(false);
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Delete Relationship"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>Unable to delete invitation, please contact support.</Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      setIsDeleting(false);
    }
  };

  return (
    <Tr key={invitation.id}>
      <Td>{invitation.data.recipientName}</Td>
      <Td>{invitation.data.recipientEmail}</Td>
      <Td>
        {invitation.data.recipient ? (
          <MdSupervisorAccount title={"Has Account"} color={"green"} />
        ) : null}
      </Td>
      <Td>{invitation.data.trial.routineDays} days</Td>
      <Td>{invitation.data.trial.understandingDays} days</Td>
      <Td>{invitation.data.upgradeToPremiumMailingList ? "Yes" : "No"}</Td>
      <Td>{formatDateLong(invitation.data.expires)}</Td>
      <Td isNumeric>
        {invitation.data.recipient && (
          <Button
            size={"xs"}
            variant={"success"}
            mr={2}
            onClick={() => {
              navigator.clipboard.writeText(
                process.env.REACT_APP_CALLBACK_ROOT_URL +
                  "invitation/accept/" +
                  invitation.id
              );
              createToast(toast, (props: any) => {
                return (
                  <CustomToast
                    title={"Copy Invitation Link"}
                    status={"Success"}
                    toast={toast}
                    toastId={props.id}
                  >
                    <Text>Invitation link copied to clipboard.</Text>
                  </CustomToast>
                );
              });
            }}
          >
            Copy Link
          </Button>
        )}
        {!invitation.data.recipient && (
          <Button
            size={"xs"}
            variant={"success"}
            mr={2}
            onClick={() => {
              navigator.clipboard.writeText(
                process.env.REACT_APP_CALLBACK_ROOT_URL +
                  "account/create?invitationId=" +
                  invitation.id
              );
              createToast(toast, (props: any) => {
                return (
                  <CustomToast
                    title={"Copy Invitation Link"}
                    status={"Success"}
                    toast={toast}
                    toastId={props.id}
                  >
                    <Text>Invitation link copied to clipboard.</Text>
                  </CustomToast>
                );
              });
            }}
          >
            Copy Link
          </Button>
        )}
        <Button
          size={"xs"}
          variant={"warning"}
          isLoading={isDeleting}
          onClick={async () => {
            deleteInvitationHandler(
              invitation.id,
              invitation.data.recipientName
            );
          }}
        >
          Delete
        </Button>
      </Td>
    </Tr>
  );
};

export { RelationshipInvitationItem };
