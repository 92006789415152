import { Heading, SimpleGrid, VStack } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { AccountSettingsAvatar } from "../../components/account/settings/AccountSettingsAvatar";
import { AccountSettingsBasic } from "../../components/account/settings/AccountSettingsBasic";
import { AccountSettingsPassword } from "../../components/account/settings/AccountSettingsPassword";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import { breadcrumbLink } from "../../types/breadcrumb";

const AccountSettingsPage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    breadcrumbLinks.push({ href: "/account", title: "Account" });
    breadcrumbLinks.push({ href: "/account/settings", title: "Settings" });
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <Heading as="h2" size="xl" mb={4}>
        Account Settings
      </Heading>
      <SimpleGrid columns={{ sm: 1, md: 2 }} gap={10} w="full">
        <VStack w="full" alignItems="flex-start">
          <AccountSettingsBasic />
        </VStack>
        <VStack w="full" alignItems="flex-start">
          <AccountSettingsPassword />
          <AccountSettingsAvatar />
        </VStack>
      </SimpleGrid>
    </VStack>
  );
};

export { AccountSettingsPage };
