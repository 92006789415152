import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  BoxProps,
  Button,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { GiPathDistance } from "react-icons/gi";
import { IoFlame, IoTimerOutline } from "react-icons/io5";
import { MdModelTraining } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { createCompletion } from "../../DataAccess/completions";
import { getSessionById } from "../../DataAccess/sessions";
import { getTrainingTimelineCurrent } from "../../DataAccess/trainingTimelines";
import { updateSessionState } from "../../features/session/sessionSlice";
import { updateTrainingTimelineState } from "../../features/trainingTimeline/trainingTimelineSlice";
import { selectUserState } from "../../features/user/userSlice";
import { trainingTimelineDataWeekDataSession } from "../../types/trainingTimeline";

interface ThisWeekSessionProps extends BoxProps {
  session: trainingTimelineDataWeekDataSession;
  lapUnit: string;
}

const ThisWeekSession: React.FC<ThisWeekSessionProps> = ({
  session,
  lapUnit,
}) => {
  const intensityColorMode = useColorModeValue(
    "ssNeonOrangeDark",
    "ssNeonOrangeLight"
  );
  // const [hamburgerMode] = useMediaQuery("(max-width: 768px)");
  const { user } = useAppSelector(selectUserState);
  const [isCompleting, setIsCompleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const completeRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const green = useColorModeValue("ssNeonGreenLight", "ssNeonGreenDark");

  const markCompleteNoActivityHandler = async (
    goalSessionId: string,
    sessionId: string,
    targetDistance: number
  ) => {
    setIsCompleting(true);
    await createCompletion(
      user ? user._id : "",
      null,
      sessionId,
      goalSessionId
    );
    const ttl = await getTrainingTimelineCurrent();
    dispatch(updateTrainingTimelineState(ttl));
    setIsCompleting(false);
  };

  const markCompleteNewHandler = async (
    goalSessionId: string,
    sessionId: string,
    targetDistance: number
  ) => {
    setIsCompleting(true);
    const sessionResult = await getSessionById(sessionId, targetDistance);
    dispatch(
      updateSessionState({
        session: sessionResult,
        targetDistance: targetDistance,
        requestedDistance: targetDistance,
      })
    );
    setIsCompleting(false);
    navigate(
      "/activities/create?goalSessionId=" +
        goalSessionId +
        "&sessionId=" +
        sessionId,
      { replace: false }
    );
  };

  const markCompleteExistingHandler = async (
    goalSessionId: string,
    sessionId: string,
    targetDistance: number
  ) => {
    setIsCompleting(true);
    const sessionResult = await getSessionById(sessionId, targetDistance);
    dispatch(
      updateSessionState({
        session: sessionResult,
        targetDistance: targetDistance,
        requestedDistance: targetDistance,
      })
    );
    setIsCompleting(false);
    navigate(
      "/activities/link?goalSessionId=" +
        goalSessionId +
        "&sessionId=" +
        sessionId,
      { replace: false }
    );
  };

  return (
    <HStack mb={4}>
      <Box h="160px" w="160px" mr={3}>
        {session.complete && (
          <VStack h="160px" w="160px" alignItems="flex-start">
            <Image borderRadius={5} src={session.image} h="160px" w="160px" />
            <Box
              h="160px"
              w="160px"
              mt={-168}
              bgColor={"rgba(53,252,134,0.25)"}
              borderRadius={5}
            >
              <Icon as={TiTick} color={green} h="50px" w="50px" />
            </Box>
          </VStack>
        )}
        {!session.complete && (
          <VStack h="160px" w="160px" alignItems="flex-start">
            <Image borderRadius={5} src={session.image} h="160px" w="160px" />
          </VStack>
        )}
      </Box>
      <VStack w="full" alignItems="full">
        <Text lineHeight="none" fontSize="small">
          <b>{session.title}</b>
        </Text>
        <Tooltip hasArrow label="Intensity">
          <span>
            <Icon
              h={3}
              w={3}
              as={IoFlame}
              color={
                session.selectedSession.data.scaled.intensity > 0
                  ? intensityColorMode
                  : "grey"
              }
            />
            <Icon
              h={3}
              w={3}
              as={IoFlame}
              color={
                session.selectedSession.data.scaled.intensity > 1
                  ? intensityColorMode
                  : "grey"
              }
            />
            <Icon
              h={3}
              w={3}
              as={IoFlame}
              color={
                session.selectedSession.data.scaled.intensity > 2
                  ? intensityColorMode
                  : "grey"
              }
            />
            <Icon
              h={3}
              w={3}
              as={IoFlame}
              color={
                session.selectedSession.data.scaled.intensity > 3
                  ? intensityColorMode
                  : "grey"
              }
            />
            <Icon
              h={3}
              w={3}
              as={IoFlame}
              color={
                session.selectedSession.data.scaled.intensity > 4
                  ? intensityColorMode
                  : "grey"
              }
            />
          </span>
        </Tooltip>
        <Text lineHeight="none">{session.name}</Text>
        <SimpleGrid columns={[2, 2, 3, 3]} w="full">
          <Tooltip hasArrow label="Distance">
            <span>
              <Icon
                h={5}
                w={5}
                color="grey"
                viewBox="0 0 100 100"
                mr={2}
                as={GiPathDistance}
              />
              <Box display="inline-block" position="relative" top="-6px">
                {session.selectedSession.data.scaled.distance}
                {lapUnit}
              </Box>
            </span>
          </Tooltip>
          <Tooltip hasArrow label="Expected Time">
            <span>
              <Icon h={5} w={5} color="grey" mr={2} as={IoTimerOutline} />
              <Box display="inline-block" position="relative" top="-6px">
                {session.selectedSession.data.scaled.time.indexOf("NaN") === -1
                  ? session.selectedSession.data.scaled.time
                  : "-"}
              </Box>
            </span>
          </Tooltip>
          <Tooltip hasArrow label="sTSS">
            <span>
              <Icon h={5} w={5} color="grey" mr={2} as={MdModelTraining} />
              <Box display="inline-block" position="relative" top="-6px">
                {session.selectedSession.data.scaled.stss
                  ? session.selectedSession.data.scaled.stss.toFixed(2)
                  : "-"}
              </Box>
            </span>
          </Tooltip>
        </SimpleGrid>
        <HStack>
          <Button
            onClick={() => {
              navigate(
                "/sessions/id/" +
                  session.selectedSession.id +
                  "/" +
                  session.selectedSession.data.scaled.distance +
                  "/" +
                  session.requestedSession.targetDistance,
                { replace: false }
              );
            }}
          >
            View Session
          </Button>
          {!session.complete && (
            <>
              <Button
                variant="success"
                px={10}
                isLoading={isCompleting}
                onClick={onOpen}
              >
                Mark Complete
              </Button>
              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={completeRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Complete Training Plan Session?
                    </AlertDialogHeader>
                    <AlertDialogBody>
                      <VStack w="full">
                        <Text mb={4}>
                          Would you like to link this plan session to existing
                          activity (from a wearable), create a new manual
                          activity or not create an activity at all?
                        </Text>
                        <Button
                          w="full"
                          variant="success"
                          px={20}
                          isLoading={isCompleting}
                          onClick={() =>
                            markCompleteExistingHandler(
                              session._id,
                              session.selectedSession.id,
                              session.requestedSession.targetDistance
                            )
                          }
                          ml={3}
                        >
                          Complete & Link to an Existing Activity
                        </Button>
                        <Button
                          w="full"
                          variant="success"
                          px={20}
                          isLoading={isCompleting}
                          onClick={() =>
                            markCompleteNewHandler(
                              session._id,
                              session.selectedSession.id,
                              session.requestedSession.targetDistance
                            )
                          }
                          ml={3}
                        >
                          Complete & Create a New Activity
                        </Button>
                        <Button
                          w="full"
                          px={20}
                          isLoading={isCompleting}
                          onClick={() =>
                            markCompleteNoActivityHandler(
                              session._id,
                              session.selectedSession.id,
                              session.requestedSession.targetDistance
                            )
                          }
                          ml={3}
                        >
                          Complete with NO Activity
                        </Button>
                      </VStack>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                      <Button
                        variant={"warning"}
                        w="full"
                        ref={completeRef}
                        onClick={onClose}
                      >
                        Don't Complete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </>
          )}
          {session.complete && session.completion.activity && (
            <Button
              onClick={() => {
                navigate("/activities/" + session.completion.activity, {
                  replace: false,
                });
              }}
            >
              View Linked Activity
            </Button>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};

export { ThisWeekSession };
