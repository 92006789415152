import {
  Button,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAffiliateByIdExtended } from "../../DataAccess/affiliates";
import { getInvitationById } from "../../DataAccess/invitations";
import { subscribeUser } from "../../DataAccess/users";
import { affiliate } from "../../types/affiliate";
import { invitation } from "../../types/invitation";
import { CoachBox } from "../coaches/CoachBox";
import { MessageDisplay } from "../generic/MessageDisplay";
import { PasswordInput } from "../ui/FormControls";
import Loading from "../ui/Loading";
import LoadingMulti from "../ui/LoadingMulti";
import { AffiliateTerms } from "./AffiliateTerms";

interface SignupProps {}

const Signup: React.FC<SignupProps> = () => {
  const [search] = useSearchParams();
  const [loadingAffiliate, setLoadingAffiliate] = useState<boolean>(true);
  const [loadingInvitation, setLoadingInvitation] = useState<boolean>(true);
  const affiliateId = search.get("affiliateId");
  const invitationId = search.get("invitationId");
  const [affiliate, setAffiliate] = useState<affiliate | null>(null);
  const [invitation, setInvitation] = useState<invitation | null>(null);
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  useEffect(() => {
    const getAffiliateByIdLocal = async () => {
      if (affiliateId) {
        try {
          const affiliateResult = await getAffiliateByIdExtended(affiliateId);
          if (affiliateResult) {
            setAffiliate(affiliateResult);
            setLoadingAffiliate(false);
          }
        } catch (error) {
          console.log(error);
          setLoadingAffiliate(false);
        }
      } else {
        setLoadingAffiliate(false);
      }
    };
    getAffiliateByIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateId]);

  useEffect(() => {
    const getInvitationByIdLocal = async () => {
      if (invitationId) {
        try {
          const invitationResult = await getInvitationById(invitationId);
          if (invitationResult) {
            setInvitation(invitationResult);
            console.log(invitationResult);
            setLoadingInvitation(false);
          }
        } catch (error) {
          console.log(error);
          setLoadingInvitation(false);
        }
      } else {
        setLoadingInvitation(false);
      }
    };
    getInvitationByIdLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  if (loadingAffiliate || loadingInvitation) {
    return (
      <LoadingMulti
        messages={["Checking affiliate links", "Checking for invitations"]}
      />
    );
  }

  if (invitationId && !invitation) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Invitation Not Found
        </Heading>
        <MessageDisplay status="error" title="Not Found">
          <Text>
            The invitation was not found. This could be because of a mis-typed
            address or the invitation could have expired. Please check the
            details or get in touch with the person who issued the invitation.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (affiliateId && !affiliate) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Affiliate Not Found
        </Heading>
        <MessageDisplay status="error" title="Not Found">
          <Text>
            The affiliate was not found. This could be because of a mis-typed
            address or the affiliate has left the affiliate program. Please
            check the details or get in touch with the person who issued the
            invitation.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (affiliate && affiliate?.data.widgetOnly) {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          Friends of Swim Smooth Program
        </Heading>
        <MessageDisplay status="error" title="Not Found">
          <Text>
            {affiliate.data.affiliateName} doesn't allow interactive signups.
            You'll need to use the Swim Smooth widget on their site.
          </Text>
        </MessageDisplay>
      </VStack>
    );
  }

  if (typeof process.env.REACT_APP_HCAPTCHA_SITE_KEY === "undefined") {
    return (
      <VStack w="full">
        <Heading as="h2" size="xl" mb={4}>
          hCaptcha Error
        </Heading>
        <MessageDisplay status="error" title="Could not get keys!">
          <Text>hCaptcha Keys not configured, please contact support.</Text>
        </MessageDisplay>
      </VStack>
    );
  }

  return (
    <SimpleGrid columns={[1, 1, 2, 2]} columnGap={10} w="full">
      <VStack spacing={2} alignItems="flex-start" w="full">
        {!affiliate && !invitation && (
          <>
            <Heading>Welcome to 'The Guru'</Heading>
            <Text>You'll need to create an account to access 'The Guru'.</Text>
            <Text>
              New accounts get a free seven day 'Routine' trial, no card details
              required.
            </Text>
            <Text>Let's get you started.</Text>
            <Center w={"full"}>
              <Image src="https://images.ctfassets.net/50b15ahactsg/1geExdwrEKi3rxDxvWByc1/fcc5dc4fea404d365d8f54bf5abcb0ee/Artboard_1.png" />
            </Center>
          </>
        )}
        {affiliate && (
          <>
            <Heading>
              {affiliate.data.coach
                ? "Swim Smooth Certified Coach Program"
                : "Friends of Swim Smooth Program"}
            </Heading>
            <Text>
              Looks like you've been sent here by one of our{" "}
              {affiliate.data.coach ? "coaches" : "friends"}. You'll be linked
              to <em>{affiliate.data.affiliateName}</em>.
            </Text>
            {affiliate.data.logo && (
              <Image
                src={
                  colorMode === "dark"
                    ? affiliate.data.logo.dark
                    : affiliate.data.logo.light
                }
                mx={20}
                my={10}
              />
            )}
            <Button
              w={"full"}
              mt={5}
              onClick={() => {
                navigate("/affiliate/accept/" + affiliateId, {
                  replace: false,
                });
              }}
            >
              Already have an account, log in and connect here
            </Button>
            <AffiliateTerms
              parentName={affiliate.data.affiliateName}
              program={
                affiliate.data.coach
                  ? "Swim Smooth Certified Coach Program"
                  : "Friends of Swim Smooth Program"
              }
            />
            {affiliate && affiliate.data.coach && (
              <Center w={"full"} my={5}>
                <CoachBox maxWidth={400} coachData={affiliate.data.coach} />
              </Center>
            )}
            {affiliate.data.paysOut && (
              <Text>
                If you go on to subscribe this will support '
                {affiliate.data.affiliateName}'.
              </Text>
            )}
          </>
        )}
        {invitation && (
          <>
            <Heading>Swim Smooth Certified Coaches Program</Heading>
            <Text as={"span"}>
              {invitation.data.recipientName}, you've been invited to connect to{" "}
              <em>{invitation.data.senderName}</em> in the Swim Smooth Guru.
            </Text>{" "}
            {invitation.data.trial && (
              <>
                <Heading as={"h3"} size={"md"} mt={4} mb={2}>
                  Free Trial
                </Heading>
                <Text as="span">
                  <em>{invitation.data.senderName}</em> is offering you
                  {invitation.data.trial.routineDays > 0 &&
                    invitation.data.trial.understandingDays > 0 && (
                      <Text as="span">
                        {" "}
                        {invitation.data.trial.routineDays} days 'Routine'
                        access, followed by{" "}
                        {invitation.data.trial.understandingDays} days
                        'Understanding' access.
                      </Text>
                    )}
                  {invitation.data.trial.routineDays > 0 &&
                    invitation.data.trial.understandingDays === 0 && (
                      <Text as="span">
                        {" "}
                        {invitation.data.trial.routineDays} days 'Routine'
                        access.
                      </Text>
                    )}
                  {invitation.data.trial.routineDays === 0 &&
                    invitation.data.trial.understandingDays > 0 && (
                      <Text as="span">
                        {" "}
                        {invitation.data.trial.understandingDays} days
                        'Understanding' access.
                      </Text>
                    )}
                  <Text as="span">
                    {" "}
                    Once your trial has expired you'll have free forever
                    'Guidance' access.
                  </Text>
                </Text>
              </>
            )}
            <Button
              w={"full"}
              mt={5}
              onClick={() => {
                navigate("/invitation/accept/" + invitationId, {
                  replace: false,
                });
              }}
            >
              Already have an account, log in and connect here
            </Button>
            <AffiliateTerms
              parentName={invitation.data.senderName}
              program={"Swim Smooth Certified Coach Program"}
            />
            {invitation.data.coach && (
              <Center w={"full"} my={5}>
                <CoachBox maxWidth={400} coachData={invitation.data.coach} />
              </Center>
            )}
          </>
        )}
      </VStack>
      <SignupForm
        affiliate={affiliate ? affiliate : null}
        invitation={invitation ? invitation : null}
      />
    </SimpleGrid>
  );
};

interface SignupFormProps {
  affiliate: affiliate | null;
  invitation: invitation | null;
}

const SignupForm: React.FC<SignupFormProps> = ({ affiliate, invitation }) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const navigate = useNavigate();
  const captchaRef = useRef<HCaptcha>(null);
  const { colorMode } = useColorMode();

  console.log("Affiliate Id", affiliate ? affiliate._id : "");
  console.log("Invitation Id", invitation ? invitation.id : "");
  const {
    control: controlSignup,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      emailAddress: invitation ? invitation.data.recipientEmail : "",
      firstName: "",
      lastName: "",
      password: "",
      gdprRequired: 0,
      gdprMailing: 0,
      gdprMarketing: 0,
      hCaptcha: "",
      hToken: "",
    },
    mode: "all",
  });

  const handleSignup = async (data: any): Promise<void> => {
    try {
      setInProgress(true);
      console.log("Affiliate Id - On submit", affiliate ? affiliate._id : "");
      console.log("Invitation Id - On submit", invitation ? invitation.id : "");
      const subscribeResponse = await subscribeUser({
        affiliateId: affiliate ? affiliate._id : "",
        invitationId: invitation ? invitation.id : "",
        emailAddress: invitation
          ? invitation.data.recipientEmail
          : data.emailAddress,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        gdprRequired: data.gdprRequired ? true : false,
        gdprMailing: data.gdprMailing ? true : false,
        gdprMarketing: data.gdprMarketing ? true : false,
        hToken: data.hToken,
      });
      if (subscribeResponse) {
        // Success - redirect to dashboard
        setInProgress(false);
        try {
          captchaRef.current?.resetCaptcha();
        } catch (error) {
          // Nothign doing
        }
        navigate("/progress", { replace: false });
      }
    } catch (error: any) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages.length > 0
      ) {
        setError(
          error.response.data.messages[0].message.user
            ? error.response.data.messages[0].message.user
            : "Unable to create account, please contact support."
        );
        setInProgress(false);
      } else {
        setError(
          error.message
            ? error.message
            : error
            ? error
            : "Unable to create account, please contact support."
        );
        setInProgress(false);
      }
    }
  };

  const handleHCaptchaVerificationSuccess = (token: string) => {
    if (token) {
      setValue("hToken", token);
    }
    trigger();
  };

  const handleHCaptchaExpire = () => {
    setValue("hToken", "");
    trigger();
  };

  if (inProgress) {
    return (
      <VStack w="full">
        <Heading as="h2" size="m" mb={4}>
          Creating Account
        </Heading>
        <Loading />
      </VStack>
    );
  }

  return (
    <VStack
      as="form"
      onSubmit={handleSubmit(handleSignup)}
      w="full"
      p={5}
      spacing={3}
      alignItems="flex-start"
    >
      <FormControl pb={1}>
        <FormLabel>
          Email
          {errors.emailAddress && (
            <span className="formError">{errors.emailAddress.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSignup}
          rules={{
            required: "Email address is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Email address is invalid",
            },
          }}
          name="emailAddress"
          render={({ field: { ref, ...restField } }) => (
            <Input
              {...restField}
              isReadOnly={
                invitation || (affiliate && affiliate.data.lockEmailAddress)
                  ? true
                  : false
              }
              isDisabled={
                invitation || (affiliate && affiliate.data.lockEmailAddress)
                  ? true
                  : false
              }
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          First Name
          {errors.firstName && (
            <span className="formError">{errors.firstName.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSignup}
          rules={{ required: "First name is required" }}
          name="firstName"
          render={({ field: { ref, ...restField } }) => (
            <Input
              {...restField}
              isReadOnly={
                affiliate && affiliate.data.lockFirstName ? true : false
              }
              isDisabled={
                affiliate && affiliate.data.lockFirstName ? true : false
              }
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          Last Name
          {errors.lastName && (
            <span className="formError">{errors.lastName.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSignup}
          rules={{ required: "Last name is required" }}
          name="lastName"
          render={({ field: { ref, ...restField } }) => (
            <Input
              {...restField}
              isReadOnly={
                affiliate && affiliate.data.lockLastName ? true : false
              }
              isDisabled={
                affiliate && affiliate.data.lockLastName ? true : false
              }
              placeholder=""
            />
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>
          Password
          {errors.password && (
            <span className="formError">{errors.password.message}</span>
          )}
        </FormLabel>
        <Controller
          control={controlSignup}
          rules={{ required: "Password is required" }}
          name="password"
          render={({ field: { ref, ...restField } }) => (
            <PasswordInput
              autoComplete="new-password"
              passwordrules="minlength: 8; required: lower; required: upper; required: digit; required: [-];"
              {...restField}
            />
          )}
        />
      </FormControl>

      <Heading as="h2" size="sm">
        Required
      </Heading>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          rules={{
            required:
              "To open a Swim Smooth Guru account you must agree to the required consents",
          }}
          name="gdprRequired"
          render={({ field: { value, onChange } }) => (
            <Checkbox spacing={8} onChange={onChange} size={"lg"}>
              <Text fontSize={"xs"} mb={2}>
                I agree to the{" "}
                <Button fontSize={"xs"} variant="link">
                  terms of service
                </Button>{" "}
                and{" "}
                <Button fontSize={"xs"} variant="link">
                  privacy policy
                </Button>{" "}
                and the collection and processing of my health data in order to
                give feedback on my swimming.
              </Text>
            </Checkbox>
          )}
        />
      </FormControl>
      <Heading as="h2" size="sm">
        Recommended
      </Heading>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          name="gdprMailing"
          render={({ field: { onChange, value } }) => (
            <Checkbox spacing={8} onChange={onChange} size={"lg"}>
              <Text fontSize={"xs"} mb={2}>
                I'm okay with my email address being added to the "Feel For The
                Water" by Swim Smooth blog and mailing list.
              </Text>
            </Checkbox>
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <Controller
          control={controlSignup}
          name="gdprMarketing"
          render={({ field: { onChange, value } }) => (
            <Checkbox spacing={8} onChange={onChange} size={"lg"}>
              <Text fontSize={"xs"} mb={2}>
                I am happy to help Swim Smooth in its mission to democratise
                access to exceptional swimming content and coaching for swimmers
                worldwide by allowing the use of my data for marketing and
                advertising purposes, including the creation of custom and
                lookalike audiences on advertising platforms.
              </Text>
            </Checkbox>
          )}
        />
      </FormControl>
      <FormControl pb={1}>
        <FormLabel>Prove you're human!</FormLabel>
        <Controller
          control={controlSignup}
          name="hCaptcha"
          render={({ field: { ref, ...restField } }) => (
            <HCaptcha
              sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY as string}
              onVerify={(token, ekey) =>
                handleHCaptchaVerificationSuccess(token)
              }
              onExpire={() => handleHCaptchaExpire()}
              onChalExpired={() => handleHCaptchaExpire()}
              onError={(event: string) => {
                console.log("Error", event);
              }}
              ref={captchaRef}
              theme={colorMode}
            />
          )}
        />
        <Controller
          control={controlSignup}
          rules={{ required: "HCaptcha Token is required" }}
          name="hToken"
          render={({ field: { ref, ...restField } }) => (
            <Input {...restField} type="hidden" />
          )}
        />
      </FormControl>
      {error && <Text color="red">{error}</Text>}
      <Button
        isDisabled={!isValid}
        isLoading={isSubmitting}
        type="submit"
        w={"full"}
      >
        Sign Up
      </Button>
      <Text>
        Already have an account?{" "}
        <Button
          variant="link"
          onClick={() => navigate("/login", { replace: false })}
        >
          login
        </Button>
        .
      </Text>
    </VStack>
  );
};

export { Signup };
