import {
  Box,
  Button,
  GridItem,
  Heading,
  Image,
  ListItem,
  OrderedList,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  BreadcrumbContext,
  breadcrumbContextType,
} from "../../components/context/BreadcrumbContext";
import Vimeo from "../../components/ui/Vimeo";
import { selectUserState } from "../../features/user/userSlice";
import { breadcrumbLink } from "../../types/breadcrumb";

const HomePage = () => {
  const { setBreadcrumbLinks } =
    useContext<breadcrumbContextType>(BreadcrumbContext);
  const { user } = useAppSelector(selectUserState);
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbLinks: breadcrumbLink[] = [];
    setBreadcrumbLinks(breadcrumbLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="full">
      <VStack w={"full"} mb={4}>
        <Heading as="h2" size={["md", "md", "xl", "xl"]}>
          Welcome to the Swim Smooth GURU!
        </Heading>
        <Text>
          All our videos, stroke insights & 1,000+ Swim Smooth sessions in your
          pocket, always.
        </Text>
        {!user && (
          <Button
            mt={5}
            mb={5}
            onClick={() => {
              navigate("/account/create", { replace: false });
            }}
          >
            GET STARTED TODAY!
          </Button>
        )}
      </VStack>
      <Box w={"full"} mb={4}>
        <Vimeo
          video={{
            id: "889751340",
            title: "",
            duration: 0,
            thumbnail: "",
          }}
        />
      </Box>
      <SimpleGrid
        columns={[1, 1, 3, 3]}
        columnGap={3}
        rowGap={3}
        w={"full"}
        mb={6}
        mt={10}
      >
        <GridItem colSpan={[1, 1, 2, 2]}>
          <VStack w={"full"} alignItems={"flex-start"}>
            <Heading as="h3" size={["sm", "sm", "lg", "lg"]} mb={3}>
              5 easy steps to get started with the GURU
            </Heading>
            <OrderedList>
              {!user && (
                <ListItem>
                  Create a FREE 7-day trial account{" "}
                  <Button
                    onClick={() => {
                      navigate("/account/create", { replace: false });
                    }}
                    variant={"link"}
                  >
                    here
                  </Button>{" "}
                  (no credit card required) and complete the simple onboarding
                  steps
                </ListItem>
              )}
              {user && (
                <ListItem>
                  Looks like you've already created an account, or you are
                  logged in with someone elses, so this step is probably done!
                </ListItem>
              )}
              <ListItem>
                Download the app (iOS){" "}
                <a
                  href="https://apps.apple.com/us/app/swim-smooth/id1516271521"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                or bookmark this webpage (Android) for ease of access
              </ListItem>
              <ListItem>
                Connect your devices and accounts{" "}
                <Button
                  onClick={() => {
                    navigate("/account/connections", { replace: false });
                  }}
                  variant={"link"}
                >
                  here
                </Button>
              </ListItem>
              <ListItem>
                Complete a little CSS test to help us set appropriate target
                paces / distances for you{" "}
                <Button
                  onClick={() => {
                    navigate("/css/calcsingle", { replace: false });
                  }}
                  variant={"link"}
                >
                  here
                </Button>
              </ListItem>
              <ListItem>
                Explore the Routine level access FREE for 7-days and consider
                subscribing to one of our levels{" "}
                <Button
                  onClick={() => {
                    navigate("/pricing", { replace: false });
                  }}
                  variant={"link"}
                >
                  here
                </Button>
              </ListItem>
            </OrderedList>
            <Text>
              Bit more of a visual learner or want to know a little more? No
              problem - we've prepared these super short videos below to get you
              up to speed quickly. Start with the “Get Started” intro and then
              browse the rest as you require. And if you'd like a full run-down
              of ALL the great features within the GURU, visit our overview{" "}
              <a
                href="https://www.swimsmooth.com/guru"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </Text>
            <Text>Enjoy and we hope to see you inside the GURU soon!</Text>
          </VStack>
        </GridItem>
        <GridItem>
          <Image
            src={
              "https://images.ctfassets.net/50b15ahactsg/2QqHRasDpsv5ydJLmceekm/e15bd139d0102894fcef5f04265ade9e/websplash.jpg"
            }
          />
        </GridItem>
      </SimpleGrid>
      <VStack w={"full"}>
        <Box w={"full"}>
          <Vimeo
            video={{
              id: "889535724",
              title: "",
              duration: 0,
              thumbnail: "",
            }}
          />
        </Box>
        <SimpleGrid columns={[1, 1, 2, 2]} columnGap={5} rowGap={5} w={"full"}>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889537159",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889538329",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889538702",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889539048",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889539377",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889539691",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889540228",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889540585",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889541101",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
          <Box w={"full"}>
            <Vimeo
              video={{
                id: "889542323",
                title: "",
                duration: 0,
                thumbnail: "",
              }}
            />
          </Box>
        </SimpleGrid>
      </VStack>
    </VStack>
  );
};

export { HomePage };
