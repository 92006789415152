import {
  BoxProps,
  Button,
  FormControl,
  Grid,
  GridItem,
  Input,
  ListItem,
  OrderedList,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { getInvitationsForUser } from "../../DataAccess/invitations";
import { getRelationshipsForUser } from "../../DataAccess/relationships";
import { updateInvitationsState } from "../../features/invitations/invitationsSlice";
import { updateRelationshipsState } from "../../features/relationships/relationshipsSlice";
import { selectUserState } from "../../features/user/userSlice";
import {
  createErrorMessage,
  createPermaToast,
  createToast,
} from "../../utils/toastHelper";
import { CreateSupportTicket } from "../ui/CreateSupportTicket";
import { CustomToast } from "../ui/CustomToast";
import Loading from "../ui/Loading";

interface BulkExtendRelationshipProps extends BoxProps {}

const BulkExtendRelationship: React.FC<BulkExtendRelationshipProps> = () => {
  const toast = useToast();
  const inputFile = useRef<any>(null);
  const { user } = useAppSelector(selectUserState);
  const dispatch = useDispatch();
  const {
    control: controlBasic,
    handleSubmit: handleSubmitBasic,
    reset: resetBasic,
    formState: { isSubmitting: isSubmittingBasic, isValid: isValidBasic },
  } = useForm({
    defaultValues: {
      bulk: "",
    },
    mode: "all",
  });

  if (!user) {
    return <Loading message={"Loading User"} />;
  }

  const handleUpload = async (data: any): Promise<void> => {
    toast.closeAll();
    try {
      // Do some stuff
      console.log(data);
      const formData = new FormData();
      formData.append("bulk", data.bulk, data.bulk.name);

      const response = await axios.post(
        process.env.REACT_APP_API_ROOT_URL + "files/relationships/bulk",
        formData
      );
      // handle the response
      createPermaToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Success"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              Bulk upload file succesfully uploaded (
              {response.data.messages.length} errors)
            </Text>
            {response.data.messages.length > 0 && (
              <OrderedList>
                {response.data.messages.map((message: string) => {
                  return <ListItem>{message}</ListItem>;
                })}
              </OrderedList>
            )}
          </CustomToast>
        );
      });
      const updatedResponseR = await getRelationshipsForUser(user._id);
      const updatedResponseI = await getInvitationsForUser(user._id);
      dispatch(updateRelationshipsState(updatedResponseR));
      dispatch(updateInvitationsState(updatedResponseI));
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      resetBasic();
    } catch (error: any) {
      createToast(toast, (props: any) => {
        return (
          <CustomToast
            title={"Add / Extend Relationship"}
            status={"Error"}
            toast={toast}
            toastId={props.id}
          >
            <Text>
              <Text>{createErrorMessage(error)}</Text>
            </Text>
            <CreateSupportTicket />
          </CustomToast>
        );
      });
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }
      resetBasic();
    }
  };

  return (
    <Grid
      templateColumns="repeat(6, 1fr)"
      gap={1}
      mb={10}
      as="form"
      onSubmit={handleSubmitBasic(handleUpload)}
      w="full"
      alignItems="flex-start"
    >
      <GridItem colSpan={5}>
        <FormControl>
          <Controller
            control={controlBasic}
            rules={{ required: true }}
            name="bulk"
            render={({ field: { ref, value, onChange, ...restField } }) => (
              <Input
                {...restField}
                onChange={(event) => {
                  if (event && event.target && event.target.files) {
                    onChange(event.target.files[0]);
                  }
                }}
                ref={inputFile}
                type="file"
                placeholder="Bulk Uload"
              />
            )}
          />
          <Text ml={1} color={"gray"} fontSize={"xs"}>
            The uploaded file must be a CSV, under 10Kb and must me in the
            correct format, including column headings, or it will not work.
          </Text>
        </FormControl>
      </GridItem>
      <Button
        isDisabled={!isValidBasic}
        isLoading={isSubmittingBasic}
        type="submit"
      >
        Upload
      </Button>
    </Grid>
  );
};

export { BulkExtendRelationship };
